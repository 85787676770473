<template>
  <div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <div class="e-handle-box" style="margin-bottom: 50px">
            <div class="handle-box">
              <span class="v-form-label">项目编号:</span>
              <el-input placeholder="项目编号" size="small" style="width:265px" v-model="itemNo" clearable
                        class="handle-input mr10"></el-input>
              <span class="v-form-label">项目名称:</span>
              <el-input placeholder="项目名称" size="small" style="width:265px" v-model="itemName" clearable
                        class="handle-input mr10"></el-input>
              <div style="float: left">
                <el-row type="flex" justify="end">
                  <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询
                  </el-button>
                </el-row>
              </div>
            </div>
          </div>
          <div class="e-table-wrapper">
            <table cellspacing="0" cellpadding="0" border="0" width="100%" style="text-align: center">
              <thead>
              <tr>
                <th class="th-l" width="68">编号</th>
                <th class="th-l" width="130">名称</th>
                <th class="th-l" width="198">第一工位</th>
                <th class="th-l" width="198">第二工位</th>
                <th class="th-l" width="198">项目销售</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row,index) in list" :key="row.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">
                <td class="td-l">
                  {{row.itemNo}}
                </td>
                <td class="td-l">
                  {{row.itemName}}
                </td>
                <td class="td-l">
                  <div style="text-align: left" v-for="item in row.singleCommissionOneList">
                    <el-row>
                      <el-col :span="19">
                        <span class="it-sp">{{item.categoryName}}</span>
                        <span class="it-sp"v-if="item.unspecifiedType==0">非指定: {{item.unspecifiedValue}} %</span>
                        <span class="it-sp" v-if="item.unspecifiedType==1">非指定: {{item.unspecifiedValue}} 元/个</span>
                        <span class="it-sp"v-if="item.appointType==0">指定: {{item.appointValue}} %</span>
                        <span class="it-sp" v-if="item.appointType==1">指定: {{item.appointValue}} 元/个</span>
                      </el-col>
                      <el-col :span="5">
                        <span class="xinzeng" style="margin-right: 6px"  @click="removeEvent(item.id)">删除</span>
                        <span class="xinzeng" @click="editEvent(item,row.itemName,1)" >修改</span>
                      </el-col>
                    </el-row>
                  </div>
                    <p class="xinzeng" @click="addSingleCommission(1,row)">新增</p>
                </td>
                <td class="td-l">
                  <div style="text-align: left" v-for="item in row.singleCommissionTwoList">
                    <el-row>
                      <el-col :span="19">
                        <span class="it-sp">{{item.categoryName}}</span>
                        <span class="it-sp"v-if="item.unspecifiedType==0">非指定: {{item.unspecifiedValue}} %</span>
                        <span class="it-sp" v-if="item.unspecifiedType==1">非指定: {{item.unspecifiedValue}} 元/个</span>
                        <span class="it-sp"v-if="item.appointType==0">指定: {{item.appointValue}} %</span>
                        <span class="it-sp" v-if="item.appointType==1">指定: {{item.appointValue}} 元/个</span>
                      </el-col>
                      <el-col :span="5">
                        <span class="xinzeng" style="margin-right: 6px" @click="removeEvent(item.id)">删除</span>
                        <span class="xinzeng" @click="editEvent(item,row.itemName,2)" >修改</span>
                      </el-col>
                    </el-row>
                  </div>
                  <p class="xinzeng" @click="addSingleCommission(2,row)">新增</p>
                </td>
                <td class="td-l">
                  <div style="text-align: left" v-for="item in row.singleCommissionThreeList">
                    <el-row>
                      <el-col :span="19">
                        <span class="it-sp">{{item.categoryName}}</span>
                        <span class="it-sp"v-if="item.unspecifiedType==0">非指定: {{item.unspecifiedValue}} %</span>
                        <span class="it-sp" v-if="item.unspecifiedType==1">非指定: {{item.unspecifiedValue}} 元/个</span>
                        <span class="it-sp"v-if="item.appointType==0">指定: {{item.appointValue}} %</span>
                        <span class="it-sp" v-if="item.appointType==1">指定: {{item.appointValue}} 元/个</span>
                      </el-col>
                      <el-col :span="5">
                        <span class="xinzeng" style="margin-right: 6px" @click="removeEvent(item.id)">删除</span>
                        <span class="xinzeng" @click="editEvent(item,row.itemName,3)" >修改</span>
                      </el-col>
                    </el-row>
                  </div>
                  <p class="xinzeng" @click="addSingleCommission(3,row)">新增</p>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="pagination">
              <el-pagination
                background
                @current-change="handleCurrentChange"
                :pager-count="7"
                layout="total, prev, pager, next,jumper"
                :total="pagination.count"
                :page-count="pagination.pages"
                :current-page="pagination.pageNumber"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 新增提成方案弹框 -->
    <el-dialog title="新增提成方案" :visible.sync="addVisible" width="50%" >
      <hr style="margin-top: -26px">
      <div class="dialog-left" style="margin-top: 8px">
        <span>项目名称</span>
        <span style="margin-left: 18px;background-color: #C4C4C4;padding: 2px 8px 2px 8px">{{item.itemName}}</span>
      </div>
      <div class="dialog-left">
        <span>员工级别</span>
        <el-select v-model="categoryId" clearable placeholder="请选择" size="small" style="margin-left: 18px">
          <el-option
            v-for="item in userGradeList"
            :key="item.id"
            :label="item.gradeName"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="dialog-left">
        <span>提成设置</span>
        <div class="yeji">
          <div>
          <span class="yeji-span">非指定</span>
            <el-radio-group v-model="unspecifiedType">
              <el-radio :label="1">固定提成</el-radio>
              <el-radio :label="0">业绩比例</el-radio>
            </el-radio-group>
            <el-input v-model="unspecifiedValue" onkeyup="value=value.replace(/[^(\d||/.)]/g,'')"  placeholder="请输入内容" size="small" style="width: 128px;margin-left: 16px"></el-input>
            <span v-if="unspecifiedType==1" style="font-size: 16px;margin-left: 6px">元 / 个</span>
            <span v-if="unspecifiedType==0" style="font-size: 16px;margin-left: 6px">%</span>
          </div>
          <div>
            <span class="yeji-span">指定</span>
            <el-radio-group v-model="appointType">
              <el-radio :label="1">固定提成</el-radio>
              <el-radio :label="0">业绩比例</el-radio>
            </el-radio-group>
            <el-input v-model="appointValue" placeholder="请输入内容" onkeyup="value=value.replace(/[^(\d||/.)]/g,'')"  size="small" style="width: 128px;margin-left: 16px"></el-input>
            <span v-if="appointType==1" style="font-size: 16px;margin-left: 6px">元 / 个</span>
            <span v-if="appointType==0" style="font-size: 16px;margin-left: 6px">%</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="saveSingleCommission">确 定</el-button>
            </span>
    </el-dialog>

    <!-- 编辑提成方案弹框 -->
    <el-dialog title="新增提成方案" :visible.sync="editVisible" width="50%" >
      <hr style="margin-top: -26px">
      <div class="dialog-left" style="margin-top: 8px">
        <span>项目名称</span>
        <span style="margin-left: 18px;background-color: #C4C4C4;padding: 2px 8px 2px 8px">{{upItemName}}</span>
      </div>
      <div class="dialog-left">
        <span>员工级别</span>
        <el-select disabled v-model="categoryId"  placeholder="请选择" size="small" style="margin-left: 18px">
          <el-option
            v-for="item in userGradeList"
            :key="item.id"
            :label="item.gradeName"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="dialog-left">
        <span>提成设置</span>
        <div class="yeji">
          <div>
            <span class="yeji-span">非指定</span>
            <el-radio-group v-model="unspecifiedType">
              <el-radio :label="'1'">固定提成</el-radio>
              <el-radio :label="'0'">业绩比例</el-radio>
            </el-radio-group>
            <el-input v-model="unspecifiedValue" onkeyup="value=value.replace(/[^(\d||/.)]/g,'')"  placeholder="请输入内容" size="small" style="width: 128px;margin-left: 16px"></el-input>
            <span v-if="unspecifiedType=='1'" style="font-size: 16px;margin-left: 6px">元 / 个</span>
            <span v-if="unspecifiedType=='0'" style="font-size: 16px;margin-left: 6px">%</span>
          </div>
          <div>
            <span class="yeji-span">指定</span>
            <el-radio-group v-model="appointType">
              <el-radio :label="'1'">固定提成</el-radio>
              <el-radio :label="'0'">业绩比例</el-radio>
            </el-radio-group>
            <el-input v-model="appointValue" placeholder="请输入内容" onkeyup="value=value.replace(/[^(\d||/.)]/g,'')" size="small" style="width: 128px;margin-left: 16px"></el-input>
            <span v-if="appointType=='1'" style="font-size: 16px;margin-left: 6px">元 / 个</span>
            <span v-if="appointType=='0'" style="font-size: 16px;margin-left: 6px">%</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="updateSingleCommission">确 定</el-button>
            </span>
    </el-dialog>

    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="delVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delSingleCommission">确 定</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
  import {Base_url} from '../../config/index'

  export default {
    data() {
      return {
        base_url: '',

        itemNo:'',
        itemName:'',
        item:'',


        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },

        addVisible: false,
        unspecifiedType:1,
        unspecifiedValue:'',
        appointType:1,
        appointValue:'',

        editVisible: false,
        editItem: {},
        upItemName:'',
        categoryId:'',

        delVisible: false,
        singleId:'',


        categoryId:'',
        userGradeList:[],




      }
    },
    async created() {
      this.searchEvent();
      this.base_url = Base_url
    },
    methods: {
      addSingleCommission(type,item){
        this.getUgCategoryList(type)
        this.item=item
        this.addVisible=true
        this.categoryId=''
        this.appointValue=0
        this.unspecifiedValue=0
      },

      //获取工位分类
      async getUgCategoryList(type){
        let data = {
          station: type,
          categoryType:3,
          isDel:0
        }
        let res = await this.$get("/admin/getUgCategoryList", data)
        this.userGradeList=res.list
      },

    //保存项目提配置
       async saveSingleCommission(){
        if (!this.categoryId){
          this.$message.error("请选择员工级别!")
          return ;
        }
         let data = {
           unspecifiedType:this.unspecifiedType,
           unspecifiedValue:this.unspecifiedValue,
           appointType:this.appointType,
           appointValue:this.appointValue,
           itemId:this.item.id,
           categoryId:this.categoryId
         }
         let res = await this.$post("/admin/saveSingleCommission", data)
         if (res.code==200){
           this.$message.success(res.message)
           this.requestSearch(this.pagination.pageNumber)
           this.addVisible=false
         }else {
           this.$message.error(res.message)
         }
       },
      //编辑项目提配置
      async updateSingleCommission(){
        let data = {
          id:this.singleId,
          unspecifiedType:this.unspecifiedType,
          unspecifiedValue:this.unspecifiedValue,
          appointType:this.appointType,
          appointValue:this.appointValue,
        }
        let res = await this.$post("/admin/saveSingleCommission", data)
        if (res.code==200){
          this.$message.success(res.message)
          this.requestSearch()
          this.editVisible=false
        }else {
          this.$message.error(res.message)
        }
      },

      handleCurrentChange(page) {
        this.requestSearch(page)
      },
      searchEvent() {
        this.requestSearch()
      },

      //分页获取项目提成配置
      async requestSearch(page) {
        let currentPage = page || 1;
        let data = {
          itemNo: this.itemNo,
          itemName: this.itemName,
          size: this.size == '' ? '10' : this.size,
          currentPage
        }
        let dataSet = await this.$get("/admin/getScServiceItemList", data)
        let res = dataSet.dataSet
        let list = res.list;
        this.list = list;
        this.pagination = {
          count: res.count,
          pageNumber: res.pageNumber,
          pageSize: res.pageSize,
          pages: res.pages
        }
      },

      addEvent() {
        this.addVisible = true;
      },

      editEvent(item,itemName,type) {
        this.getUgCategoryList(type)
        this.upItemName=itemName
        this.singleId=item.id
        this.categoryId=item.categoryId
        this.unspecifiedType=item.unspecifiedType
        this.unspecifiedValue=item.unspecifiedValue
        this.appointType=item.appointType
        this.appointValue=item.appointValue
        this.editVisible = true;
      },

       removeEvent(singleId) {
        this.singleId=singleId
        this.delVisible = true;
      },

     async delSingleCommission(){
        let data = {
          id:this.singleId,
        }
        let res = await this.$post("/admin/delSingleCommission", data)
        if (res.code==200){
          this.$message.success(res.message)
          this.requestSearch()
          this.delVisible=false
        }else {
          this.$message.error(res.message)
        }
      },

    },
  }

</script>

<style scoped>

  .th-l {
    text-align: center;
    padding-right: 10px;

  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }
  .xinzeng {
    margin: 0 auto;
    width: 38px;
   color: #1890ff;
   cursor:pointer;
  }
  .dialog-left{
    font-size: 16px;
    text-align: left;
  }
  .dialog-left span{
    line-height: 38px;
  }
  .yeji{
    margin-left: 38px;
    background-color: #FFDDDD;
    padding: 8px 8px 8px 8px;
    width: 88%;
  }
  .yeji-span{
    width:66px;
    display:inline-block;
    text-align: right;
    margin-right: 16px;
  }
  .it-sp{
    margin-right: 6px;
    font-weight: bold;
    font-size: 10px;
  }
</style>
