<template>
  <div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <div class="e-handle-box" style="margin-bottom: 50px">
            <div class="handle-box">
              <span class="v-form-label">员工姓名:</span>
              <el-input placeholder="员工姓名" size="small" style="width:265px" v-model="nickName" clearable
                        class="handle-input mr10"></el-input>
              <div style="float: left">
                <el-row type="flex" justify="end">
                  <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询
                  </el-button>
                </el-row>
              </div>
              <div style="float: left">
                <el-row type="flex" justify="end">
                  <el-button size="small" class="e-button" type="primary" @click="toItemBase">设置
                  </el-button>
                </el-row>
              </div>
            </div>
          </div>

          <div class="e-table-wrapper">
            <table cellspacing="0" cellpadding="0" border="0" width="100%" style="text-align: center">
              <tbody>
              <tr v-for="(row,index) in list" :key="row.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">
                <td class="td-l" style="width: 88px;">
                  <div>
                    <span style="background-color:#F6EFEF;width:100%;display: block ">{{row.employeeName}}</span>
                    <span style="margin-top: 8px;display: block;color: #1890ff;cursor: pointer" @click="addMultiple(row.employeeId)">新增</span>
                  </div>
                </td>
                <td class="td-l" style="text-align: left;">
                  <div class="item" v-for="(item,index) in row.multipleCommissionList" >
                      <span >项目范围:{{item.cmsnRange}}</span>
                      <span >项目提成:{{item.cmsnValue}}</span>
                      <span @click="editEvent(item)" style="color: #1890ff;margin-right: 6px; cursor: pointer;">编辑</span>
                      <span @click="removeEvent(item)" style="color: #1890ff;  cursor: pointer;">删除</span>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="pagination">
              <el-pagination
                background
                @current-change="handleCurrentChange"
                :pager-count="7"
                layout="total, prev, pager, next,jumper"
                :total="pagination.count"
                :page-count="pagination.pages"
                :current-page="pagination.pageNumber"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
                <el-button  size="small" @click="delVisible = false">取 消</el-button>
                <el-button  size="small" type="primary" @click="delMultipleCommission">确 定</el-button>
            </span>
    </el-dialog>

    <!-- 新增框 -->
    <el-dialog title="新增" :visible.sync="addVisible" width="300px" center>
      <div class="multiple">
        <span >项目范围:</span>
        <el-input size="small" v-model="addItem.cmsnRange" onkeyup="value=value.replace(/\s+/g,'')" style="width: 260px" placeholder="请输入内容"></el-input><span>例如:0-100</span>
        <br>
        <br>
        <span >项目提成:</span>
        <el-input size="small" v-model="addItem.cmsnValue" onkeyup="value=value.replace(/[^(\d||/.)]/g,'')" style="width: 260px" placeholder="请输入内容"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="addVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="saveMultiple">确 定</el-button>
            </span>
    </el-dialog>

    <!-- 编辑框 -->
    <el-dialog title="编辑" :visible.sync="editVisible" width="300px" center>
      <div class="multiple">
        <span >项目范围:</span>
        <el-input size="small" v-model="cmsnRange" onkeyup="value=value.replace(/\s+/g,'')"   style="width: 260px" placeholder="请输入内容"></el-input><span>例如:0-100</span>
        <br>
        <br>
        <span >项目提成:</span>
        <el-input size="small" v-model="cmsnValue" onkeyup="value=value.replace(/[^(\d||/.)]/g,'')" style="width: 260px" placeholder="请输入内容"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="editVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="updateMultipleCommission">确 定</el-button>
            </span>
    </el-dialog>

  </div>
</template>

<script>
  import {Base_url} from '../../config/index'

  export default {
    data() {
      return {
        base_url: '',
        nickName: '',
        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },
        addVisible: false,
        employeeId:'',
        addItem:{},

        editVisible: false,
        delVisible: false,

        editItem: {},
        multipleId:'',
        cmsnRange:'',
        cmsnValue:'',

        selItem: {},
      }
    },
    async created() {
      this.searchEvent();
      this.base_url = Base_url
    },
    methods: {
      toItemBase(){
        this.$router.push('/commis/itemCommission/itemBase')
      },
      addMultiple(employeeId){
        this.addVisible=true
        this.addItem={
          cmsnRange:'',
          cmsnValue:'',
          employeeId:employeeId
        }
      },

      handleCurrentChange(page) {
        this.requestSearch(page)
      },
      searchEvent() {
        this.requestSearch()
      },
      async requestSearch(page) {
        let currentPage = page || 1;
        let data = {
          nickName: this.nickName,
          size: this.size == '' ? '10' : this.size,
          currentPage
        }
        let dataSet = await this.$get("/admin/getEmpMultipleCommissionList", data)
        let res = dataSet.dataSet
        let list = res.list;
        this.list = list;
        this.pagination = {
          count: res.count,
          pageNumber: res.pageNumber,
          pageSize: res.pageSize,
          pages: res.pages
        }
      },
      async saveMultiple(){
        if (!this.addItem.cmsnRange){
          this.$message.error("请填写项目范围")
          return ;
        }
        if (!this.addItem.cmsnValue){
          this.$message.error("请填写项目提成")
          return ;
        }
        let data ={
          employeeId:this.addItem.employeeId,
          cmsnRange:this.addItem.cmsnRange,
          cmsnValue:this.addItem.cmsnValue,
        }
        let res = await this.$post("/admin/saveMultipleCommission",data);
        if (res.code==200){
          this.addVisible=false
          this.requestSearch()
          this.$message.success(res.message)
        }else {
          this.$message.error(res.message)
        }
      },
      async updateMultipleCommission(){
        let data={
          id:this.multipleId,
          cmsnRange:this.cmsnRange,
          cmsnValue:this.cmsnValue,
        }
        let res = await this.$post("/admin/updateMultipleCommission", data)
        if (res.code==200){
          this.requestSearch()
          this.editVisible = false;
          this.$message.success(res.message)
        }else {
          this.$message.error(res.message)
        }
      },
      async delMultipleCommission(){
        let  data ={
          id:this.selItem.id
        }
        let res = await this.$post("/admin/delMultipleCommission", data)
        if (res.code==200){
          this.requestSearch()
          this.delVisible=false
          this.$message.success(res.message)
        }else {
          this.$message.error(res.message)
        }
      },

      addEvent() {
        this.addVisible = true;
      },

      editEvent(item) {
        this.multipleId=item.id
        this.cmsnRange=item.cmsnRange
        this.cmsnValue=item.cmsnValue
        this.editVisible = true;
      },
      async removeEvent(item) {
        this.selItem = item
        this.delVisible = true;
      },
    },
  }

</script>

<style scoped>
  .item {
    width: 100%;
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 8px;

  }
  .item span{
    display: inline-block;
    margin-right: 38px;
    inline-height: 28px;
    font-size: 13px;
  }
  .multiple{
    margin-bottom: 18px;
  }
  .multiple span{
    display: inline-block;
    line-height: 32px;
    text-align: right;
    margin-right: 8px;
    margin-left: 8px;
  }

</style>
