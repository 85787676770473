<template>
  <div>
    <div class="e-breadcrumb">项目提成配置管理</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <div class="e-card-tabs">
            <div class="e-tabs-wrapper">
              <div class="e-tabs-item" style="margin-left: 20px" :class="{'active':tabIndex==1}" @click="switchTable(1)">按项目数提成
              </div>
              <div class="e-tabs-item" :class="{'active':tabIndex==0}" @click="switchTable(0)">单个项目设置提成
              </div>
              <div class="e-tabs-item" :class="{'active':tabIndex==2}" @click="switchTable(2)">按销售业绩阶梯式提成
              </div>
              <div class="e-tabs-item" :class="{'active':tabIndex==3}" @click="switchTable(3)">分类别项目设置提成
              </div>
            </div>
          </div>
          <TabSingle  v-if="tabIndex==0"/>
          <TabMultiple v-if="tabIndex==1"/>
          <TabSale  v-if="tabIndex==2"/>
          <TabType  v-if="tabIndex==3"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TabMultiple from './multiple'
  import TabSingle from './single'
  import TabSale from './sale'
  import TabType from './type'

  export default {
    data() {
      return {
        tabIndex:1,
        item: {},
        radio:1,
      }
    },
    async created() {
      console.log(this.$route)
      // if (this.$route.query.tabIndex){
      //   this.tabIndex=this.$route.query.tabIndex
      // }
    },
    methods: {

      switchTable(tabIndex) {
        this.tabIndex = tabIndex
      },

    },
    components: {
      TabMultiple,
      TabSingle,
      TabSale,
      TabType,
    }
  }

</script>

<style scoped>
  .e-table-wrapper tbody .el-button{
    padding: 3px 0px;
  }
</style>
